var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "p": ['1rem', '0px']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: {
          name: 'client.index'
        }
      }, {
        label: 'Profil',
        isCurrent: true
      }]
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "bg": "white",
      "border": "1px solid #f2f2f2",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "16px",
      "w": "100%",
      "p": ['10px', '24px']
    }
  }, [_c('c-text', {
    attrs: {
      "d": ['none', 'block'],
      "align": "center",
      "mx": "auto",
      "font-family": "Roboto",
      "font-size": "28px",
      "color": "#111",
      "font-weight": "700"
    }
  }, [_vm._v(" Data Klien ")]), _vm.profile.dataProfile ? _c('c-flex', {
    attrs: {
      "p": ['8px', '16px'],
      "align-items": "center",
      "width": ['100%', '600px'],
      "mx": "auto",
      "mb": "16px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.profile.dataProfile.photoUrl),
      "alt": _vm.fullName,
      "w": ['100px', '150px'],
      "h": ['100px', '150px'],
      "rounded": "full",
      "object-fit": "cover"
    }
  }), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "justify": "center",
      "ml": ['1.5rem', '2rem']
    }
  }, [_c('c-text', {
    attrs: {
      "color": "#111",
      "font-weight": "400",
      "font-size": ['12px', '16px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama ")]), _c('c-text', {
    attrs: {
      "mt": ['0px', '4px'],
      "color": "#000",
      "font-weight": "500",
      "font-size": ['14px', '18px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.fullName) + " ")]), _c('c-text', {
    attrs: {
      "mt": ['8px', '16px'],
      "font-weight": "400",
      "font-size": ['12px', '16px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nomor Handphone ")]), _c('c-text', {
    attrs: {
      "mt": ['0px', '4px'],
      "color": "#000",
      "font-weight": "500",
      "font-size": ['14px', '18px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.profile.dataProfile.phone) + " ")]), _c('c-text', {
    attrs: {
      "mt": ['8px', '16px'],
      "font-weight": "400",
      "font-size": ['12px', '16px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Email ")]), _c('c-text', {
    attrs: {
      "mt": ['0px', '4px'],
      "color": "#000",
      "font-weight": "500",
      "font-size": ['14px', '18px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.profile.dataProfile.email) + " ")])], 1)], 1) : _c('SkeletonProfile'), _c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'column-reverse'],
      "gap": ['10px', '16px']
    }
  }, [_c('ActiveProgram', {
    attrs: {
      "type": "profile"
    }
  }), _c('c-box', {
    attrs: {
      "padding": ['0 16px', 'unset']
    }
  }, [_c('CardInfoReferral', {
    attrs: {
      "type": "profile"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "mt": "16px",
      "d": ['block', 'none']
    }
  }, [_c('menu-item', {
    attrs: {
      "title": "Akun",
      "to": _vm.$router.resolve({
        name: 'client.profile.account'
      }).route.fullPath,
      "border-top": true
    }
  }), _c('menu-item', {
    attrs: {
      "title": "Riwayat Program",
      "to": _vm.$router.resolve({
        name: 'client.profile.programHistory'
      }).route.fullPath
    }
  }), _c('menu-item', {
    attrs: {
      "title": "Riwayat Transaksi",
      "to": _vm.$router.resolve({
        name: 'client.profile.historyTransaction'
      }).route.fullPath
    }
  }), _c('menu-item', {
    attrs: {
      "title": "Bahasa",
      "to": _vm.$router.resolve({
        name: 'under-maintenance'
      }).route.fullPath
    }
  }), _c('menu-item', {
    attrs: {
      "title": "Pusat Bantuan",
      "to": _vm.$router.resolve({
        name: 'under-maintenance'
      }).route.fullPath
    }
  }), _c('c-flex', {
    attrs: {
      "as": "button",
      "h": "64px",
      "p": "16px",
      "w": "100%",
      "justify-content": "space-between",
      "align-items": "center"
    },
    on: {
      "click": _vm.openModalConfirm
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "color": "red.900",
      "font-weight": "500"
    }
  }, [_vm._v(" Logout ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chevron-right-black.svg'),
      "alt": "icon arrow right"
    }
  })], 1)], 1)], 1), _c('ModalConfirmLogout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isConfirm,
      expression: "isConfirm"
    }],
    attrs: {
      "is-open": _vm.isConfirm
    },
    on: {
      "handle-close": _vm.handleCloseConfirmModal,
      "logOut": _vm.logout
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }